import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import AetnaClaimService from "@/core/services/AetnaClaimService";

@Module
export default class AetnaClaimModule extends VuexModule {
  page: any = {
    data: [],
    total: 0,
    totalPages: 0
  }

  @Action
  [Actions.LOAD_AETNA_CLAIMS](filter) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    AetnaClaimService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_AETNA_CLAIMS, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })

  }

  @Mutation
  [Mutations.SET_AETNA_CLAIMS](data) {
    this.page = data
  }
}