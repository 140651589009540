import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import UserModule from "@/store/modules/UserModule";
import ProductModule from "@/store/modules/ProductModule";
import PermissionModule from "@/store/modules/PermissionModule";
import PolicyModule from "@/store/modules/PolicyModule";
import CaseModule from "@/store/modules/CaseModule";
import LocationModule from "@/store/modules/LocationModule";
import CompanyModule from "@/store/modules/CompanyModule";
import ActivityModule from "@/store/modules/ActivityModule";
import ValidationModule from "@/store/modules/ValidationModule";
import DocumentModule from "@/store/modules/DocumentModule";
import ContactModule from "@/store/modules/ContactModule";
import ClaimModule from "@/store/modules/ClaimModule";
import PanelModule from "@/store/modules/PanelModule";
import ProviderModule from "@/store/modules/ProviderModule";
import LookupModule from "@/store/modules/LookupModule";
import InvoiceModule from "@/store/modules/InvoiceModule";
import AuditModule from "@/store/modules/AuditModule";
import NoteModule from "@/store/modules/NoteModule";
import EntityMetaModule from "@/store/modules/EntityMetaModule";
import TimelineModule from "@/store/modules/TimelineModule";
import LoadingModule from "@/store/modules/LoadingModule";
import TemplateModule from "@/store/modules/TemplateModule";
import ReserveModule from "@/store/modules/ReserveModule";
import ExchangeRateModule from "@/store/modules/ExchangeRateModule";
import LoginModule from "@/store/modules/LoginModule";
import PaymentModule from "@/store/modules/PaymentModule";
import DashboardModule from "@/store/modules/DashboardModule";
import PageModule from "@/store/modules/PageModule";
import ContentMappingModule from "@/store/modules/ContentMappingModule";
import QuicksightModule from "@/store/modules/QuicksightModule";
import CheckListModule from "@/store/modules/CheckListModule";
import ActionTemplateModule from "@/store/modules/ActionTemplateModule";
import PolicyIntegrationModule from "@/store/modules/PolicyIntegrationModule";
import NotificationModule from "@/store/modules/NotificationModule";
import FileShareModule from "@/store/modules/FileShareModule";
import ActionViewModule from "@/store/modules/ActionViewModule";
import ProductBenefitModule from "@/store/modules/ProductBenefitModule";
import GroupModule from "@/store/modules/GroupModule";
import TaskModule from "@/store/modules/TaskModule";
import WebSocketModule from "@/store/modules/WebSocketModule";
import ChatModule from "@/store/modules/ChatModule";
import TagModule from "@/store/modules/TagModule";
import ProcessModule from "@/store/modules/ProcessModule";
import LogReportModule from "@/store/modules/LogReportModule";
import LogReportNotificationModule from "@/store/modules/LogReportNotificationModule";
import {ServiceModule} from "@/store/modules/ServiceModule";
import {ServicePaymentModule} from "@/store/modules/ServicePaymentModule";
import CustomerModule from "@/store/modules/CustomerModule";
import CustomerInboxModule from "@/store/modules/CustomerInboxModule";
import ScanModule from "@/store/modules/ScanModule";
import {ReminderModule} from "@/store/modules/ReminderModule";
import SanctionCheckModule from "@/store/modules/SanctionCheckModule";
import EmailModule from "@/store/modules/EmailModule";
import ReportBuilderModule from "@/store/modules/ReportBuilderModule";
import AetnaClaimModule from "@/store/modules/AetnaClaimModule";


config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    UserModule,
    ProductModule,
    PermissionModule,
    PolicyModule,
    CaseModule,
    LocationModule,
    CompanyModule,
    ActivityModule,
    ValidationModule,
    DocumentModule,
    ContactModule,
    ClaimModule,
    PanelModule,
    ProviderModule,
    LookupModule,
    InvoiceModule,
    AuditModule,
    NoteModule,
    EntityMetaModule,
    TimelineModule,
    LoadingModule,
    TemplateModule,
    ReserveModule,
    ExchangeRateModule,
    LoginModule,
    PaymentModule,
    DashboardModule,
    PageModule,
    ContentMappingModule,
    QuicksightModule,
    CheckListModule,
    ActionTemplateModule,
    PolicyIntegrationModule,
    NotificationModule,
    FileShareModule,
    ActionViewModule,
    ProductBenefitModule,
    GroupModule,
    TaskModule,
    WebSocketModule,
    ChatModule,
    TagModule,
    ProcessModule,
    LogReportModule,
    LogReportNotificationModule,
    ServiceModule,
    ServicePaymentModule,
    CustomerModule,
    CustomerInboxModule,
    ScanModule,
    ReminderModule,
    SanctionCheckModule,
    EmailModule,
    ReportBuilderModule,
    AetnaClaimModule,
  },
});

export default store;
