import {BaseService} from "@/core/services/BaseService";
import {buildFormData} from "@/core/helpers/functions";

class AetnaClaimService extends BaseService<any> {
  endpoint = "/aetna-claim"

  async upload(data) {
    const formData = buildFormData(data);
    return await this.uploadHttp().post(this.endpoint + "/upload", formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data
    })
  }

  async getClaimData(id) {
    return this.http().get(this.endpoint + "/" + id + "/claims").then(res => {
      return res.data
    })
  }

  async link(id, invoiceId) {
    return this.http().put(this.endpoint + "/" + id + "/link/" + invoiceId).then(res => {
      return res.data
    })
  }

  async uploadDocument(data) {
    return await this.uploadHttp().post(this.endpoint + "/document-upload", data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      return res.data
    })
  }
}

export default new AetnaClaimService();