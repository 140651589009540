import { computed } from "vue";

export default {
  mounted(el, binding) {
    const vm = binding.instance
    const currentUser = computed<any>(() => vm.$store?.getters.currentUser)
    if (currentUser.value === undefined || currentUser.value === null) {
      el.classList.add("d-none")
    }
    vm.$store.watch(() => vm.$store.state.AuthModule.user, (user: any) => {
      console.log("is internal", user.internalUser)
      if (user && !user.internalUser) {
        console.log("remove child", user.internalUser)
        if (el.parentNode) {

          el.parentNode.removeChild(el);
        }
      } else {
        el.classList.remove("d-none")
      }
    })
    if (!currentUser.value && !currentUser.value.internalUser) {
        el.parentNode.removeChild(el);
    }
  }
}