import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import LocationService from "@/core/services/LocationService";
import {ILocation} from "@/core/entity/ILocation";


@Module
export default class LocationModule extends VuexModule {
  countries = Array<any>();
  states = Array<any>();
  stateAll = Array<any>([]);

  @Action
  async [Actions.LOAD_COUNTRY]() {
    await LocationService.list('COUNTRY').then(res => {
      this.context.commit(Mutations.SET_LOCATION_COUNTRY, res);
    })
  }

  @Action
  async [Actions.LOAD_STATE](code: string) {
    if (code) {
      await LocationService.listByCode(code).then(res => {
        this.context.commit(Mutations.SET_LOCATION_STATE, res);
      })
    } else {
      await LocationService.list('STATE').then(res => {
        this.context.commit(Mutations.SET_LOCATION_STATE, res);
      })
    }

  }

  @Action
  async [Actions.LOAD_STATE_ALL]() {
    await LocationService.list('STATE').then(res => {
      this.context.commit(Mutations.SET_LOCATION_STATE_ALL, res);
    })
  }

  @Mutation
  [Mutations.SET_LOCATION_COUNTRY](data: ILocation[]) {
    this.countries = data
  }

  @Mutation
  [Mutations.SET_LOCATION_STATE](data: ILocation[]) {
    this.states = data
  }
  @Mutation
  [Mutations.SET_LOCATION_STATE_ALL](data: ILocation[]) {
    this.stateAll = data
  }
}